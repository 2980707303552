.Header {
  width: 100%;
  height: 48px;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.2);
  font-family: Helvetica, sans-serif;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
}

.Header a {
  display: inline-block;
  height: 48px;
  line-height: 48px;

  padding: 0 8px;
}

.Header .user {
  line-height: 48px;

  button {
    margin: 0 8px;
  }

  span {
    display: inline-block;
    margin: 0 8px;
  }
}
